import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL;

export const JobApi = {
    getJobApi: (params) => {
        const url = `${baseUrl}/job/custom-solr/search`
        return axios.get(url, { params });
    },
    getMeApi: (uid) => {
        const url = `${baseUrl}/job-genius/me/${uid}`
        return axios.get(url);
    },
    doNotSellMyInfoApi: (data) => {
        const url = `${baseUrl}/job-genius/do_not_sell_my_info`
        return axios.post(url, data);
    },
    fetchLogo: (data) => {
        const url = `https://api.api-ninjas.com/v1/logo?name=${data}`
        return axios.get(url, {
            headers: {
                'X-Api-Key': process.env.REACT_APP_API_KEY,
            },
        });
    },
}   