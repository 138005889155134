export const useAbandonPixel = () => {

    const fireAbandonPixel = (payOutRev) => {
        const queryParams = new URLSearchParams(window.location?.search);
        const cid = queryParams.get('jgcid');
        const txid = queryParams.get('txid');
        const cy = queryParams.get('cy');

        const IMG = document.createElement('img');
        let src = `https://donsorsstaingham.com/postback?cid=${cid}&payout=${payOutRev}&et=d0rev`;

        if (txid) src += `&txid=${txid}`;
        if (cy) src += `&cy=${cy}`;

        IMG.src = src;
        IMG.width = '0';
        IMG.height = '0';
        IMG.style.display = "none";
        IMG.id = 'abandonPixel';
        document.getElementById('root').appendChild(IMG);
    };

    return fireAbandonPixel;
};
