import React from "react";
import { createBrowserRouter } from "react-router-dom";
import JobOffering from "./JobOffering/JobOffering";
const ErrorPage = React.lazy(() => import('./ErrorPage/ErrorPage'));
const LandingPage = React.lazy(() => import('./LandingPage/LandingPage'));
const HomePage = React.lazy(() => import('./HomePage/HomePage'));
const DoNotSell = React.lazy(() => import('./DoNotSellMyInformation/DoNotSell'));
const CaPrivacyPolicy= React.lazy(() => import('./CaPrivacyPolicy/CaPrivacyPolicy'));

const routes = createBrowserRouter([
    {
        path: '/',
        element: <LandingPage />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: 'job-offering',
                element: <JobOffering />
            },
        ]
    },
    {
        path: 'do-not-sell-my-information',
        element: <DoNotSell />
    },
    {
        path: 'ca-privacy-policy',
        element: <CaPrivacyPolicy />
    }
]);

export default routes;