import { TYPE_TRACKING } from "../../constants/TrackingConstant";

const FireTracking = async (type = TYPE_TRACKING.pageOpen, data) => {
    const dataTracking = {
        ...data,
        customEvent: type
    }
    // eslint-disable-next-line no-undef
    switch (type) {
        case TYPE_TRACKING.pageOpen:
            // eslint-disable-next-line no-undef
            await opix('event', '2', {
                ...dataTracking,
            });
            break;
        case TYPE_TRACKING.userInfo:
            // eslint-disable-next-line no-undef
            await opix('event', '2', {
                ...dataTracking,
            });
            break;
        case TYPE_TRACKING.applyJob:
            // eslint-disable-next-line no-undef
            await opix('event', '2', {
                ...dataTracking,
            });
            break;
        case TYPE_TRACKING.displayJob:
            // eslint-disable-next-line no-undef
            await opix('event', '2', {
                ...dataTracking,
            });
            break;
        default:
            break;
    }
}

export default FireTracking;