const pixelFiringFunction = (cid, et, txid = "", payout = "") => {
    let IMG = document.createElement('img');

    // Base URL for the image source
    let src = `https://donsorsstaingham.com/conversion.gif?cid=${cid}&et=${et}`;

    // Conditionally add txid and payout if they are provided
    if (txid) {
        src += `&txid=${txid}`;
    }

    if (payout) {
        src += `&payout=${payout}`;
    }

    IMG.src = src;
    IMG.width = '0';
    IMG.height = '0';
    IMG.style.display = "none";
    IMG.id = 'imgTracking_';

    document.getElementById('root').appendChild(IMG);
};

export default pixelFiringFunction;